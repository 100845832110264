import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Hero from './Hero';
import EpoxyInfo from './EpoxyInfo';
import FlakeCarousel from './FlakeCarousel';
import ContactForm from './ContactForm';
import Gallery from './Gallery';
import Warranty from './Warranty';
import GlobalStyle from './GlobalStyle';
import Footer from './Footer';
// import Shop from './Shop'; // Temporarily disable the Shop component
import Commercial from './Commercial';
import Classes from './Classes';
import Snake from './Snake'; // Import Snake component for game

function App() {
  return (
    <Router>
      <GlobalStyle /> {/* Add global styles */}
      <Header /> {/* Render Header */}

      {/* Define Routes */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero /> {/* Render Hero component */}
                <EpoxyInfo /> {/* Render Epoxy Info section */}
                <Warranty /> {/* Render Warranty section */}
                <FlakeCarousel /> {/* Render Image Carousel */}
                <Gallery /> {/* Render Gallery section */}
                <ContactForm /> {/* Render Contact Us section */}
              </>
            }
          />
          {/* <Route path="/shop" element={<Shop />} /> */} {/* Shop route temporarily disabled */}
          <Route path="/commercial" element={<Commercial />} /> {/* Commercial route */}
          <Route path="/classes" element={<Classes />} /> {/* Classes route */}
          <Route path="/snake" element={<Snake />} /> {/* Snake Game route */}
          <Route path="*" element={<h1>404 - Page Not Found</h1>} /> {/* 404 Route */}
        </Routes>
      </Suspense>

      <Footer /> {/* Render Footer */}
    </Router>
  );
}

export default App;
