import React from 'react';
import styled from 'styled-components';
import lifetimeWarrantySeal from './images/carfloor.webp'; // Import the warranty seal

// Styled Components for the Warranty Page
const WarrantySection = styled.section`
  background-color: #0f4c81;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 50px 20px;
`;

// Container to hold both text and image
const WarrantyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw; 
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 90vw;
  }
`;

// Box for warranty text
const WarrantyTextContainer = styled.div`
  width: 60%;
  text-align: left;
  margin-right: 50px;

  @media (max-width: 768px) {
    width: 85vw; /* Set width to 85vw on mobile */
    text-align: left; /* Keeps text aligned to the left */
    margin: 0;
  }
`;

// Container for the image and caption
const WarrantyImageContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 90vw; /* 
  }
`;

// Image for the lifetime warranty seal
const WarrantyImage = styled.img`
  width: 100%; /* Full width within the container */
  height: auto;
  border-radius: 15px;
  margin-top: 10px;
`;

// Caption for the image
const ImageCaption = styled.p`
  font-size: 0.9rem;
  color: #cccccc;
  text-align: center;
  margin-top: 10px;
  padding: 0 10px;
`;

const WarrantyHeading = styled.h2`
  font-size: 2.5rem;
  margin-top: 10px;
  margin-bottom: 40px;


`;

const WarrantyText = styled.p`
  font-size: 1.3rem;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const Warranty = () => {
  return (
    <WarrantySection>
      <WarrantyContainer>
        <WarrantyTextContainer>
          <WarrantyHeading>Lifetime Warranty</WarrantyHeading>
          <WarrantyText>
            We're so confident in the quality and durability of our epoxy flooring system that we offer a lifetime warranty on every floor we install. When you choose Next Level Epoxy Flooring, you're not just getting a beautiful, durable floor—you're getting peace of mind.
          </WarrantyText>
          <WarrantyText>
            Our floors are built to last, and we stand behind them 100%. If anything goes wrong due to material defects or installation errors, we've got you covered. That's how sure we are about the quality of our product.
          </WarrantyText>
        </WarrantyTextContainer>
        
        {/* Image and Caption Container */}
        <WarrantyImageContainer>
          <WarrantyImage src={lifetimeWarrantySeal} alt="Lifetime Warranty Seal" />
          <ImageCaption>
            A recent project in Santa Fe, New Mexico, featuring our most popular color - Nightfall.
          </ImageCaption>
        </WarrantyImageContainer>
      </WarrantyContainer>
    </WarrantySection>
  );
};

export default Warranty;
